<template>
  <footer>
    <div class="footerNav">
      <div v-if="!loggedIn">
        <a href="../login">Log In </a>|
        <a href="../pc-repair-ticket">Open Support Ticket </a>
      </div>
    </div>
    COPYRIGHT © {{ currentYear }}
    <a href="https://www.pcsimpleweb.co.uk/" target="_blank"
      >PCSimple Web Design</a
    >
    All rights Reserved
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footerNav {
  margin-bottom: 0.7em;
}
@media only screen and (max-width: 540px) {
  footer {
    font-size: 12px;
  }
}
</style>
