import { createRouter, createWebHistory } from "vue-router";

import ForgotPassword from "./components/ForgotPassword.vue";
import Login from "./components/Login.vue";
import PCRepairTicket from "./components/PCRepairTicket.vue";
import PCRepairTicketCreate from "./components/PCRepairTicketCreate.vue";
import ResetPassword from "./components/ResetPassword.vue";
import TicketReport from "./components/TicketReport.vue";

// lazy-loaded
const Profile = () => import("./views/Profile.vue");
const Dashboard = () => import("./views/Dashboard.vue");
const CompletedTickets = () => import("./views/CompletedTickets.vue");
const Ticket = () => import("./views/Ticket.vue");
const NewTicket = () => import("./views/NewTicket.vue");
const NewAccount = () => import("./views/NewAccount.vue");
const ITTakeOver = () => import("./views/ITTakeOver.vue");
// const ViewTicket = () => import("./views/ViewTicket.vue");

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "Login | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/pc-repair-ticket",
    name: "PCRepairTicket",
    component: PCRepairTicket,
    meta: {
      title: "PC Repair Ticket | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/create-repair-ticket",
    name: "PCRepairTicketCreate",
    component: PCRepairTicketCreate,
    meta: {
      title: "Create Ticket | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/new-account",
    name: "new-account",
    component: NewAccount,
    meta: {
      title: "Create New Account | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: {
      title: "Forgot Password | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPassword,
    meta: {
      title: "Reset Password | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/completed-tickets",
    name: "completed-tickets",
    // lazy-loaded
    component: CompletedTickets,
    meta: {
      requiresAuth: true,
      title: "Completed Tickets | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/",
    name: "dashboard",
    // lazy-loaded
    component: Dashboard,
    meta: {
      requiresAuth: true,
      title: "Dashboard | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/ticket/:TicketNumber",
    name: "ticket",
    // lazy-loaded
    component: Ticket,
    meta: {
      title: "View Ticket | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
    meta: {
      requiresAuth: true,
      title: "Profile | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/new-ticket",
    name: "new-ticket",
    // lazy-loaded
    component: NewTicket,
    meta: {
      requiresAuth: true,
      title: "Create New Ticket | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/ticket-report",
    name: "ticket-report",
    // lazy-loaded
    component: TicketReport,
    meta: {
      title: "Ticket Report | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/it-take-over",
    name: "it-take-over",
    // lazy-loaded
    component: ITTakeOver,
    meta: {
      title: "IT Take Over | Tickets Portal - Fortify247 Ltd",
    },
  },
  {
    path: "/*",
    component: 404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta.title;
  if (title) {
    document.title = title;
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!localStorage.getItem("user")) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
