import { createStore } from "vuex";
import { auth } from "./auth.module";
import { serviceDesk } from "./serviceDesk.module";
import { responsive } from "./responsive.module";

const store = createStore({
  modules: {
    auth: auth,
    serviceDesk: serviceDesk,
    responsive: responsive,
  },
});

export default store;
