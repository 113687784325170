import axios from "axios";
import router from "../router";
import { jwtDecrypt, tokenAlive } from "../services/jwtDecoder";
import authHeader from "./auth-header";

// Development
// const API_URL = "http://localhost:8080/auth/";

//Live
const API_URL = "https://api.fortify247.net/auth/";

class AuthService {
  async isEmailExist(email) {
    return await axios
      .post(API_URL + "checkemail", {
        email: email,
      })
      .then(async (response) => {
        return response.data;
      });
  }

  login(user) {
    return axios
      .post(API_URL + "signin", {
        email: user.email,
        password: user.password,
      })
      .then(async (response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  getUserByEmail(user) {
    return axios.post(API_URL + "get-user", {
      email: user.userEmail,
    });
  }

  createAccount(user) {
    return axios.post(API_URL + "create-account", {
      firstName: user.firstName,
      lastName: user.lastName,
      Address1: user.address1,
      Address2: user.address2,
      City: user.city,
      State: user.province,
      ZipCode: user.postalCode,
      Phone: user.phone,
      Email: user.email,
      Password: user.password,
      tags: user.tags,
    });
  }

  createExistAccount(user) {
    return axios.post(API_URL + "create-exist-account", {
      email: user.email,
      password: user.password,
    });
  }

  forgotPassword(user) {
    return axios
      .post(API_URL + "forgot-password", {
        email: user.email,
      })
      .then((response) => {
        /* need to remove this */
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  resetPassword(data) {
    return axios
      .post(API_URL + "reset-password", {
        token: data.token,
        password: data.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  async refreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    const decryptUser = jwtDecrypt(user.accessToken);
    const isTokenLive = tokenAlive(decryptUser.exp);
    if (!isTokenLive) {
      localStorage.removeItem("user");
      await axios
        .post(API_URL + "refreshtoken", {
          refreshToken: user.refreshToken,
        })
        .then((response) => {
          if (response.data.accessToken) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                accountId: user.accountId,
                accountName: user.accountName,
                locationName: user.locationName,
                locationId: user.locationId,
                email: user.email,
                roles: user.roles,
                accessToken: response.data.accessToken,
                refreshToken: response.data.refreshToken,
              })
            );
          }
        })
        .catch((error) => {
          console.log(error);
          router.push("/login");
        });
    }
    return true;
  }

  register(user) {
    return axios.post(API_URL + "signup", {
      email: user.email,
      password: user.password,
      roles: ["moderator", "user"],
    });
  }

  updatePassword(password) {
    const user = JSON.parse(localStorage.getItem("user"));

    return axios.put(
      API_URL + "update-password",
      {
        email: user.email,
        oldPassword: password.oldPassword,
        password: password.password,
      },
      { headers: authHeader() }
    );
  }
}

export default new AuthService();
