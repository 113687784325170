import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";

// Development
// const API_URL = "http://localhost:8080/servicedesk/";

//Live
const API_URL = "https://api.fortify247.net/servicedesk/";

class ServiceDeskService {
  async getAllTickets() {
    await AuthService.refreshToken();
    try {
      let user1 = JSON.parse(localStorage.getItem("user"));
      const account = user1.accountId;

      return axios.post(
        API_URL + "tickets",
        {
          accountId: account,
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async addNote(data) {
    await AuthService.refreshToken();

    let user = JSON.parse(localStorage.getItem("user"));

    try {
      return axios.post(
        API_URL + "note",
        {
          ticketId: data.ticketId,
          noteSummary: data.noteSummary,
          email: user.email,
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async getAllNotes(ticketId) {
    await AuthService.refreshToken();
    try {
      return axios.post(
        API_URL + "notes",
        {
          ticketId: parseInt(ticketId),
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async getTicket(ticketId) {
    try {
      var ticket = axios.get(API_URL + "ticket/" + ticketId);
      return (await ticket).data.ticket[0];
    } catch (err) {
      console.log(err);
    }
  }

  async getNotes(ticketId) {
    try {
      var notes = axios.get(API_URL + "notes/" + ticketId);
      return (await notes).data.notes;
    } catch (err) {
      console.log(err);
    }
  }

  getHardwareAssets() {
    AuthService.refreshToken();
    try {
      let user1 = JSON.parse(localStorage.getItem("user"));
      const accountId = user1.accountId;

      return axios.post(
        API_URL + "hardwareassets",
        {
          accountId: accountId,
        },
        { headers: authHeader() }
      );
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  getHardwareAsset(accountId) {
    try {
      return axios.post(API_URL + "hardwareAsset", {
        accountId: accountId,
      });
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  getTicketByNumber(ticketNumber) {
    try {
      return axios.get(API_URL + "ticket-by-number/" + ticketNumber);
    } catch (err) {
      console.log(err);
      return err;
    }
  }

  async createTicket(ticket, email) {
    await AuthService.refreshToken();

    let user = JSON.parse(localStorage.getItem("user"));

    return axios.post(
      API_URL + "ticket",
      {
        AccountId: user.accountId,
        AccountLocationId: user.locationId,
        ContactId: user.id,
        Email: email,
        TicketSourceId: 0,
        TicketTypeId: parseInt(ticket.ticketType),
        Title: ticket.title,
        Details: ticket.details,
        PriorityId: parseInt(ticket.priority),
        StatusId: 33729,
        QueueId: 25955,
        PrimaryAssigneeId: 34677,
        AffectedHardwareAssetId: parseInt(ticket.hardwareAsset),
      },
      { headers: authHeader() }
    );
  }

  async createPCTicket(ticket) {
    return axios.post(API_URL + "PCticket", {
      AccountId: ticket.AccountId,
      AccountLocationId: ticket.AccountLocationId,
      ContactId: ticket.ContactId,
      Email: ticket.Email,
      TicketSourceId: 0,
      TicketTypeId: ticket.TicketTypeId,
      Title: ticket.Title,
      Details: ticket.Details,
      PriorityId: ticket.PriorityId,
      StatusId: 33729,
      QueueId: 27752,
      PrimaryAssigneeId: 40082,
      AffectedHardwareAssetId: ticket.AffectedHardwareAssetId,
      DevicePassword: ticket.DevicePassword,
    });
  }

  async getAccount(accountId) {
    try {
      var account = await axios.get(API_URL + "account/" + accountId);
      return account.data;
    } catch (err) {
      console.log(err);
    }
  }

  async getContact(contactId) {
    try {
      var contact = await axios.get(API_URL + "contact/" + contactId);
      return contact.data;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ServiceDeskService();
