<template>
  <div class="horizontal-container header">
    <div class="title-wrapper">
      <router-link to="/" class="nav__logo">
        Fortify 247 Portal
      </router-link>
    </div>
    <div class="profile-wrapper">
      <div class="name-wrapper">
        <p class="name">{{ firstName }} {{ lastName }}</p>
        <p class="account">{{ accountName }}</p>
      </div>
      <div class="icon">
        <img src="..\assets\images\white-pp.png" alt="avatar" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    accountName: String,
    firstName: String,
    lastName: String,
  },
};
</script>

<style scoped>
.nav__logo {
  color: #fff;
  font-size: 1.4em;
}

a {
  text-decoration: none !important;
}

.horizontal-container {
  background: #12192c;
}

ion-icon {
  margin-right: 4px;
  margin-bottom: -4px !important;
  font-size: 1.2rem;
  color: #212529;
}

.header {
  border-radius: 5px !important;
}

.nav__link {
  background-color: white;
}

.nav__link {
  display: inline;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0;
  padding: 0;
  color: #212529;
  border-radius: 0;
  margin-bottom: 0px;
  transition: 0.3s;
  cursor: pointer;
}

.icon img {
  margin-bottom: 0px;
  width: 30px;
  border-radius: 50%;
}

.icon {
  padding-left: 10px;
}

.title-wrapper {
  width: calc(100% - 150px);
}
.profile-wrapper {
  display: flex;
  text-align: right;
  width: 300px;
  justify-content: flex-end;
}

.account {
  font-size: 12px;
}

p {
  margin-top: 0;
  margin-bottom: 0rem;
}

.name-wrapper .name {
  font-weight: 600 !important;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #ffffff;
  font-size: 14px;
}

.name-wrapper .account {
  color: #ffffff;
}

.title-wrapper {
  float: right;
}
</style>
