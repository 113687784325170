<template>
  <div class="login">
    <div class="card card-container">
      <div class="company">
        <a href="/">
          <img
            id="profile-img"
            src="https://www.fortify247.net/wp-content/uploads/2020/12/fortify247-header-logo-black.png"
            class="profile-img-card"
        /></a>
      </div>
      <h4>Welcome to The Fortify Portal! 👋</h4>
      <p>Please sign-in to your account and create/view tickets</p>
      <Form @submit="handleLogin" :validation-schema="schema">
        <div class="form-group">
          <label for="email">Email</label>
          <Field name="email" type="email" class="form-control" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="form-group">
          <div class="flex-rows">
            <label for="password">Password</label>
            <router-link to="/forgot-password"
              ><small>Forgot Password?</small></router-link
            >
          </div>
          <Field name="password" type="password" class="form-control" />
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Login</span>
          </button>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
    <div class="new-ticket">
      <router-link to="/new-account">Create Portal Account</router-link>
    </div>
  </div>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "Login",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      email: yup.string().required("Email is required!"),
      password: yup.string().required("Password is required!"),
    });

    return {
      loading: false,
      message: "",
      schema,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    }
  },
  methods: {
    handleLogin(user) {
      this.loading = true;

      this.$store.dispatch("auth/login", user).then(
        () => {
          this.$router.push("/");
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.new-ticket {
  text-align: center;
  padding: 1rem 0;
  border-top: solid 1px #d8d6de;
}
.new-ticket a {
  font-weight: 500;
  color: #7367f0;
  text-decoration: none;
  font-size: 0.8rem;
}

.flex-rows a {
  font-family: "poppins";
  color: #7367f0;
  float: right;
  text-decoration: none;
  display: inline;
}

.alert {
  font-weight: 600;
  font-size: 1rem;
  padding: 0.71rem 1rem;
  margin-bottom: 0;
  margin-top: 0.71rem;
}

.alert-danger {
  background-color: #fbdddd;
  border-color: #f9cfcf;
  box-shadow: rgb(234 84 85 / 40%) 0px 6px 15px -7px;
  color: #ea5455;
}

.btn-primary {
  border-color: #7367f0 !important;
  background-color: #7367f0 !important;
  color: #fff !important;
  font-family: "poppins";
  font-size: 0.9rem;
}

.company {
  text-align: center;
}

.company img {
  margin-bottom: 2.5rem !important;
}

.login h4 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 1rem !important;
  line-height: 1.2;
  color: #5e5873;
  font-family: "poppins";
}

.login p {
  margin-bottom: 1.5rem !important;
  font-family: "poppins";
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  text-align: left;
}

#profile-img {
  width: 150px;
}

.login {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.5rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border: 1px solid rgba(34, 41, 47, 0.125);
  border-radius: 0.428rem;
  background-color: white;
}

.error-feedback {
  color: #ea5455;
  font-size: 0.8rem;
  font-family: "poppins";
  font-weight: 500;
}

label {
  color: #5e5873;
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
  font-family: "poppins";
  font-weight: 500;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.438rem 0.438rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.card-container.card {
  width: 300px !important;
}

.card {
  background-color: white;
  padding: 15px 0px 15px 0px;
  margin: auto;
  border: 0px;
  box-shadow: 0 0 0 0 rgb(34 41 47 / 10%);
}

@media screen and (max-width: 400px) {
  .login {
    width: calc(100% - 1.5em);
    padding: 1rem !important;
  }
  .card {
    padding: 0;
  }

  .card-container.card {
    width: 100% !important;
  }
}
@media screen and (max-width: 500px) {
  .login {
    padding: 0.5rem !important;
  }
}
</style>
