export const responsive = {
  namespaced: true,
  state: {
    expand: true,
  },
  mutations: {
    expandTrue(state) {
      state.expand = true;
    },
    expandFalse(state) {
      state.expand = false;
    },
  },
  actions: {
    toggleExpand({ commit }) {
      commit("expandFalse");
    },
    toggleClose({ commit }) {
      commit("expandTrue");
    },
  },
};
