import ServiceDeskService from "../services/serviceDesk.service";

export const serviceDesk = {
  namespaced: true,
  state: {
    tickets: "",
    completedTickets: "",
    hardwareAssets: "",
  },
  mutations: {
    ticketsInitializeSuccess(state, tickets) {
      state.tickets = tickets.filter(function(el) {
        return el.StatusId != 33731 && el.StatusId != 33876;
      });
      state.completedTickets = tickets.filter(function(el) {
        return el.StatusId == 33731 || el.StatusId == 33876;
      });
      state.allTickets = tickets;
    },
    hardwaresInitializeSuccess(state, hardwareAssets) {
      state.hardwareAssets = hardwareAssets;
    },
  },
  actions: {
    async initializeTickets({ commit }) {
      var tickets = await ServiceDeskService.getAllTickets();
      commit("ticketsInitializeSuccess", tickets.data.tickets);
    },
    async initializeHardwareAssets({ commit }) {
      var hardwareAssets = await ServiceDeskService.getHardwareAssets();
      commit("hardwaresInitializeSuccess", hardwareAssets.data.hardware);
    },
  },
};
